.App {
  font-family: sans-serif;
  text-align: center;
}

body {
  background-color: #000;
  margin: 0 auto;
}

h1,
p {
  color: #fff;
}

p {
  margin: 0.5rem;
}
